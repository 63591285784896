var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v(_vm._s(_vm.$t('c:stock-details:Stock Inventories with Difference List')))]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-1",attrs:{"cols":"1"}},[_c('v-menu',{ref:"fromDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('c:stock-details:From date'),"dense":"","outlined":"","readonly":""},model:{value:(_vm.filters.fromDate),callback:function ($$v) {_vm.$set(_vm.filters, "fromDate", $$v)},expression:"filters.fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.fromDateMenu = false}},model:{value:(_vm.filters.fromDate),callback:function ($$v) {_vm.$set(_vm.filters, "fromDate", $$v)},expression:"filters.fromDate"}})],1)],1),_c('v-col',{staticClass:"mr-3",attrs:{"cols":"1"}},[_c('v-menu',{ref:"toDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('c:stock-details:To date'),"dense":"","outlined":"","readonly":""},model:{value:(_vm.filters.toDate),callback:function ($$v) {_vm.$set(_vm.filters, "toDate", $$v)},expression:"filters.toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.toDateMenu = false}},model:{value:(_vm.filters.toDate),callback:function ($$v) {_vm.$set(_vm.filters, "toDate", $$v)},expression:"filters.toDate"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.executeReport}},[_vm._v(" "+_vm._s(_vm.$t('c:stock-details:Fetch data'))+" ")]),(_vm.stockInventoriesWithDiffListData)?_c('v-btn',{staticClass:"white blue--text mr-3",attrs:{"loading":_vm.exportJsonLoading},on:{"click":_vm.handleJsonDownload}},[_vm._v(" "+_vm._s(_vm.$t('c:stock-details:JSON'))+" ")]):_vm._e(),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" "+_vm._s(_vm.$t('c:stock-details:Clear'))+" ")])],1)],1),(_vm.loading)?[_c('v-row',{staticClass:"my-6",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100"}})],1)]:_vm._e(),(_vm.stockInventoriesWithDiffListData)?_c('div',[(_vm.stockInventoriesWithDiffListData)?_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.tableHeaders,"items":_vm.stockInventoriesWithDiffListData,"item-key":"id","dense":"","outlined":"","items-per-page":100,"footer-props":{ showFirstLastPage: true, 'items-per-page-options': [10, 25, 50, 100, 250, -1] }},scopedSlots:_vm._u([{key:"item.differences",fn:function(ref){
var item = ref.item;
return [(item.differences && item.differences.length)?_c('span',{staticClass:"light-blue--text text-decoration-underline cursor-pointer",on:{"click":function($event){return _vm.openDifferencesDialog(item.differences)}}},[_vm._v(" "+_vm._s(_vm.$t('c:stock-details:Show differences'))+" ")]):_vm._e()]}}],null,false,2463750731)}):_vm._e()],1):_vm._e(),_c('v-dialog',{model:{value:(_vm.differencesDialog),callback:function ($$v) {_vm.differencesDialog=$$v},expression:"differencesDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('c:stock-details:Differences'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.differencesDialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.differenceTableHeaders,"items":_vm.selectedDifferences,"item-key":"productId","dense":"","hide-default-footer":""}})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeDifferencesDialog}},[_vm._v(_vm._s(_vm.$t('Close')))])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }